<template>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">

    <section class="content-header p-1 px-2">
      <div class="div-header container-fluid">
        <div style="border-bottom: 1px solid #0001" class="row mb-2">
          <div class="col-sm-12 d-flex justify-content-between align-items-center">
            <h4 style="color: #0007" class="mt-3 mb-3"></h4>
            <div>
              <button class="btn btn-sm btn-outline-secondary mb-1" @click="goBack()">
                <i class="fa fa-arrow-left mx-1"></i>Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="row d-flex justify-content-center" id="NRC">
        <div class="d-flex col-12 mt-4">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Nome</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="text"
                id="name"
                name="name"
                class="form-control"
                placeholder="Nome..."
                v-model="admin.name"
            />
          </div>
        </div>

        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Senha</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="password"
                name="password"
                id="password"
                class="form-control"
                placeholder="Senha..."
                v-model="admin.password"
            />
          </div>
        </div>

        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Confirmar Senha</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                class="form-control"
                placeholder="Confirmar Senha..."
                v-model="admin.confirmPassword"
            />
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12  d-flex justify-content-center ">
          <button class="btn-big btn btn-default" @click="clear()">Limpar</button>
          <button class="btn-big btn btn-success ml-3"
                  @click="update()">Atualizar
          </button>
        </div>
      </div>
      <hr/>
    </section>

    <section class="content" v-if="admin && admin.seller_id && admin.role === 'seller'">
      <div class="d-flex d-flex justify-content-center mt-4">
        <div class="label">
          <h5><b> URL de Comissão </b></h5>
        </div>
      </div>
      <div class="d-flex col-12 mt-4">
        <div class="label d-flex justify-content-end col-lg-3">
          <label class="word-custom">Basic</label>
        </div>
        <div class="form-group col-lg-2">
          <input
              type="text"
              class="form-control"
              disabled
              v-model="admin.seller_id[0].Basic"
          />
        </div>
        <!--<div v-clipboard="admin.seller_id[0].Basic"
             tag="button"
             class="form-control col-lg-1 text-center button"
             v-clipboard:success="clipboardSuccessHandler"
             v-clipboard:error="clipboardErrorHandler">
          Copiar
        </div>-->
        <div class="label d-flex justify-content-end col-lg-1">
          <label class="word-custom">Essencial</label>
        </div>
        <div class="form-group col-lg-2">
          <input
              type="text"
              class="form-control"
              disabled
              v-model="admin.seller_id[1].Essential"
          />
        </div>
        <!--<div v-clipboard="admin.seller_id[1].Essential"
             tag="button"
             class="form-control col-lg-1 text-center button"
             v-clipboard:success="clipboardSuccessHandler"
             v-clipboard:error="clipboardErrorHandler">
          Copiar
        </div>-->
      </div>
      <div class="d-flex col-12 mt-4">
        <div class="label d-flex justify-content-end col-lg-3">
          <label class="word-custom">Avançado</label>
        </div>
        <div class="form-group col-lg-2">
          <input
              type="text"
              class="form-control"
              disabled
              v-model="admin.seller_id[2].Advanced"
          />
        </div>
        <!--<div v-clipboard="admin.seller_id[2].Advanced"
             tag="button"
             class="form-control col-lg-1 text-center button"
             v-clipboard:success="clipboardSuccessHandler"
             v-clipboard:error="clipboardErrorHandler">
          Copiar
        </div>-->
        <div class="label d-flex justify-content-end col-lg-1">
          <label class="word-custom">Profissional</label>
        </div>
        <div class="form-group col-lg-2">
          <input
              type="text"
              class="form-control"
              disabled
              v-model="admin.seller_id[3].Professional"
          />
        </div>
        <!--<div v-clipboard="admin.seller_id[3].Professional"
             tag="button"
             class="form-control col-lg-1 text-center button"
             v-clipboard:success="clipboardSuccessHandler"
             v-clipboard:error="clipboardErrorHandler">
          Copiar
        </div>-->
      </div>
      <hr/>
    </section>


  </div>
  <!-- /.content-wrapper -->
</template>

<script>
export default {
  data() {
    return {
      admin: {},
      nameAdmin: '',
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1)
    },

    clipboardSuccessHandler({value, event}) {
      this.$toasted.global.defaultSuccess({msg: `Copiado com sucesso.`})
    },

    clipboardErrorHandler({value, event}) {
      this.$toasted.global.defaultError({msg: `Não foi possivel copiar.`})
    },

    getManager() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] =
          `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.get(`auth/manager/${this.$route.params.id}`,)
          .then(resp => {
            resp.data.password = ''
            this.admin = resp.data
            this.nameAdmin = resp.data.name
          })
          .catch(e => {

            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    update() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.put(`auth/manager/${this.$route.params.id}`, {
          creator: this.$store.state.admin.name,
          ...this.admin
        })
          .then(resp => {
            this.$toasted.global.defaultSuccess({msg: `Usuário Atualizado.`})
            this.$router.push(-1)
          })
          .catch(e => {
            if (e.response.data.error) {
              if (e.response.data.error.code == 11000) {
                this.$toasted.global.defaultError({msg: 'Email já existe!'})
                return false
              }
            }
            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    clear() {
      this.admin = {}
    }
  },

  created() {
    this.getManager()
    document.title = 'Tallos Usuários - Editar Usuário'
  }
}
</script>

<style scoped>

.content-wrapper {
  background-color: #fff;
}

.button:hover {
  color: #ffffff;
  background-color: #2b982b;
}

.btn-big {
  width: 180px;
  padding: 8px;
}

</style>